import React, { Component } from 'react';
import Drawer from 'react-drag-drawer';
import Cookies from 'universal-cookie';

export class Sender extends Component {
    constructor(base) {
        super();
        this.state = {
            message: '',
            resps: '',
            name: '',
            logs: [],
            imgLink: '',
            logsModal: false,
            doneModal: false,
            selectedExport: 0,
            logsData: ''
        };
    }
    logs_modal_toggle = () => {
        let { logsModal } = this.state

        this.setState({ logsModal: !logsModal })
    }
    done_modal_toggle = () => {
        let { doneModal } = this.state

        this.setState({ doneModal: !doneModal })
    }
    respsInArray = () => {
        var resps = this.state.resps.split(',');
        var arr = [];
        for (var i = 0; i < resps.length; i++) {
            arr.push(parseInt(resps[i]));
        }
        return arr;
    }
    componentDidMount = () => {
        this.getLogs();
    }
    sendMSG = () => {
        var json = {
            resps: this.respsInArray(),
            image: this.state.imgLink,
            name: this.state.name,
            message: this.state.message
        };
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(json)
        };
        fetch('Dashboard/SendCustomMessage', requestOptions).then(x => { this.done_modal_toggle(); this.getLogs(); });
    }
    setProject = (name, message, img) => {
        this.setState({ name: name, message: message, imgLink: img });
    }
    getLogs = () => {
         fetch('Dashboard/GetLogs').then(x => x.json()).then(x => this.setState({ logs: x }));
    }
    getAllUsers = () => {
        fetch('Dashboard/GetUsers').then(x => x.json()).then(x => this.setState({ resps: x.resps.join(',') }));
    }
    uploadImage = (file) => {
        //
        var formData = new FormData();
        formData.append('excel', file);
        formData.append('name', file.name);
        const requestOptions = {
            method: 'POST',
            // headers: { 'Content-Type': 'application/json' },
            body: formData
        };
        fetch('Dashboard/UploadImage', requestOptions).then(x => x.status).then(x => this.setState({ imgLink: x === 200 ? 'https://viberapi.opinion.com.ua/img/' + file.name : '' }));
    }
    uploadResps = (file) => {
        var formData = new FormData();
        formData.append('excel', file);
        const requestOptions = {
            method: 'POST',
            // headers: { 'Content-Type': 'application/json' },
            body: formData
        };
        fetch('Dashboard/ExcelData', requestOptions).then(x => x.json()).then(x => this.setState({ resps: x }));
    }

    render() {
        return (
            <div className="send-block">
                <Drawer
                    open={this.state.doneModal}
                    onRequestClose={this.done_modal_toggle}>
                    <div Style="background: white;padding: 15px;width: 450px; height: 235px;" className="test">
                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                            <img onClick={() => this.done_modal_toggle()} src="img/close_24px.svg" />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70%', textAlign: 'center' }}>
                            <h3 Style="font-weight: 700;color: #333333;">Повідомлення<br />відправлені</h3>
                        </div>
                        
                    </div>

                </Drawer>
                <Drawer
                    open={this.state.logsModal}
                    onRequestClose={this.logs_modal_toggle}>
                    <div Style="background: white;padding: 25px 25px 05px 25px;" className="test">
                        <table Style="background: #f5f5f5" className="table">
                            {this.state.logsData.split(',').map(x =>
                                <tr>
                                    <td>{x}</td>
                                </tr>
                            )}
                        </table>
                    </div>

                </Drawer>
                <p>1. Додавання користувачів Viber Bot</p>
                <p onClick={() => this.setState({ selectedExport: 0 })} className="check-men"> <img src={this.state.selectedExport === 0 ? 'img/radio_checked.svg' : 'img/radio_unchecked.svg'} />Вказати користувачів вручну</p>
                <div className="send-block" style={{ display: this.state.selectedExport === 0 ? '' : 'none' }}>
                    <span onClick={() => this.getAllUsers()} className="low">Вказати всі</span>
                    <div>

                        <br />
                        <textarea placeholder="Вкажіть ID користувачів через кому" onInput={(e) => this.setState({ resps: e.target.value })} value={this.state.resps} />
                    </div>
                </div>
                <p onClick={() => this.setState({ selectedExport: 1 })} className="check-men"> <img src={this.state.selectedExport === 1 ? 'img/radio_checked.svg' : 'img/radio_unchecked.svg'} />Завантажити список користувачів (респондентів)</p>
                <div className="send-block" style={{ display: this.state.selectedExport === 1 ? '' : 'none' }}>
                    <span>Cписок користувачів (респондентів) для розсилки (xls, xlsx)</span>
                    <br />
                    <label for="file" className="file-button"><p>оберіть файл</p><i style={{ display: 'none' }}>Файл не вибран</i></label>
                    <input type="file" onInput={(e) => this.uploadResps(e.target.files[0])} style={{ display: 'none' }} id="file" multiple="false" />
                </div>
                <p>2. Налаштування розсилки</p>
                <div className="send-block">
                    <span>Назва розсилки</span>
                    <br />
                    <input placeholder="Вкажіть назву розсилки" onInput={(e) => this.setState({ name: e.target.value })} value={this.state.name} style={{ borderColor: '#dadada' }} type="text" />
                </div>
                <div className="send-block" style={{ marginTop: '42px' }}>
                    <b style={{ whiteSpace: 'nowrap' }}>Текст повідомлення</b>
                    <textarea placeholder="Вкажіть назву розсилки" onInput={(e) => this.setState({ message: e.target.value })} value={this.state.message} style={{ width: 'calc(100% - 40px)' }} />
                </div>
                <br />
                <span>Додати картинку</span>
                <br />
                <br />
                <label for="file-image" className="file-button"><p>оберіть файл</p><i style={{ display: this.state.imgLink.length > 0 ? 'none' : '' }}>Файл не вибран</i>  <label style={{ marginLeft: '15px', display: this.state.imgLink.length > 0 ? '' : 'none' }} className="file-button"><a target="_blank" href={this.state.imgLink}>Показати картинку</a></label></label>
                <input type="file" onInput={(e) => this.uploadImage(e.target.files[0])} style={{ display: 'none' }} id="file-image" multiple="false" />
                <hr />

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}><button onClick={() => this.sendMSG()} className="btn-prime">Розіслати</button></div>

                <div style={{ display: 'flex', alignItems: 'baseline', marginTop: '76px' }}>
                    <h3 style={{ fontWeight: '700' }}>Звіт по розсилці </h3>
                    
                </div>
                <div>
                    <table class="n-table">
                        <tr style={{ textAlign: 'center' }}>
                            <th>ID</th>
                            <th>Назва розсилки</th>
                            <th>Дата и час</th>
                            <th></th>
                        </tr>
                        {this.state.logs.map(x =>
                            <tr>
                                <td>{x.id}</td>
                                <td>{x.name}</td>
                                <td>{x.date}</td>
                                <td><button onClick={() => this.setState({ logsModal: true, logsData: x.sended })}>Переглянути звіт</button></td>
                                <td><button onClick={() => this.setProject(x.name, x.message, x.link_path)}>завантажити шаблон</button></td>
                            </tr>
                        )}
                    </table>
                </div>


            </div>
        );
    }
}
export default Sender;